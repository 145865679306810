<template>
    <div class="index">
        <div class="list">
            <div class="item" v-for="(itme, index) in dataList" :key="index">
                <img class="type_img" :src="itme.img" alt="" @click="toDetail(itme)">
            </div>
        </div>
        <img class="mncs" src="../assets/img/mncs@2x.png" alt="">
    </div>
</template>
<script>
import axios from 'axios'
// import { myfun } from '../assets/js/tool'
// import test from './test2.json'
export default {
    name: 'Index',
    data() {
        return {
            dataList: [],
            url:''
        }
    },
    created() {
        // var one = myfun(test, 1)
        //  console.log(JSON.stringify(one));
        var u = navigator.userAgent
            var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端 
          
            if (isiOS) {
              this.url = window.location.href
            } 
      

        this.homePage()
        this.getData()
    },
    methods: {
        // 获取数据
        getData() {
            var that = this
            axios.get("/api/edu/educoursecatalog/selfAssessment").then(res => {
                var data = res.data.data
                data.forEach((e) => {
                    if (e.unitName == "低学段") { e.img = require("../assets/img/dxd@2x.png") }
                    else if (e.unitName == "中学段") { e.img = require("../assets/img/zxd@2x.png") }
                    else if (e.unitName == "高学段") { e.img = require("../assets/img/gxd@2x.png") }
                })
                that.dataList = data;
                //  console.log(that.dataList);
            }).catch(err => {
                console.log(err);
            })
        },
        // 记录进入页面人数埋点
        homePage() {
            axios.get("/api/edu/caiHongKeyOrderInfo/homePage").then(res => {
                console.log(res);
            }).catch(err => {
                console.log(err);
            })

        },
        toDetail(itme) {
            // console.log(itme);
            this.$router.push({ name: 'Answer', query: { id: itme.id,url: this.url } })

        },
    }
}
</script>
<style scoped>
.index {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 20px 15px 0;
}

.list {
    width: 100%;
    padding: 100px 15px 0;
    background-image: url('../assets/img/mncs_bg@2x.png');
    background-size: 100% 100%;
}

.item {
    margin-top: 12px;
    width: 315px;
    height: 90px;
}

.type_img {
    width: 100%;
    height: 100%;
}

.mncs {
    margin-top: 30px;
    width: 345px;
}
</style>